import React from "react"
import MKBox from "components/MKBox"
import { Outlet } from "react-router-dom"
import DefaultNavbar from "examples/Navbars/DefaultNavbar"

function NavbarLayout() {
  return (
    <>
      <MKBox bgColor="white" shadow="sm" py={0.25}>
        <DefaultNavbar
          logoUrl="https://firebasestorage.googleapis.com/v0/b/hotel-innvie.appspot.com/o/logo%2Flogo-stay-white.svg?alt=media&token=d74fe2a2-90fb-4f2a-be0e-742c3887145f"
          transparent
          relative
          sticky
        />
      </MKBox>
      <main>
        <Outlet />
      </main>
      <MKBox pt={6} px={1} mt={6} sx={{ backgroundColor: "transparent" }} />
    </>
  )
}

export default NavbarLayout
