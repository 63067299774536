/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import PropTypes from "prop-types"
import Autocomplete from "@mui/material/Autocomplete"
import MKInput from "components/MKInput"

// Otis Kit PRO components

function SelectPicker({ options, onChange, value, ...rest }) {
  return (
    <Autocomplete
      options={options}
      value={value}
      onChange={onChange}
      renderInput={(params) => <MKInput {...params} {...rest} />}
    />
  )
}

SelectPicker.defaultProps = {
  options: [],
  value: "",
  onChange: () => {
    // console.log("onChange not defined")
  },
}

SelectPicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.string),
}

export default SelectPicker
