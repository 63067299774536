import { doc, getDoc, setDoc, Timestamp } from "firebase/firestore"

import { useState, useEffect } from "react"
import db from "../firebase"

function useUtils() {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [utilsData, setUtilsData] = useState({ startDate: null, endDate: null })
  const docRef = doc(db, "utils", "Q5ppreLoMwJsAOdUYGBi")

  const fetchUtilsData = async () => {
    setLoading(true)
    try {
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        setUtilsData(docSnap.data())
      } else {
        console.log("No such document in 'utils'!")
        setUtilsData({ startDate: null, endDate: null })
      }
    } catch (e) {
      console.error("Error fetching 'utils' data:", e)
      setError(e)
    }
    setLoading(false)
  }

  const updateUtilsData = async (startTimeString, endTimeString) => {
    setLoading(true)
    try {
      // Asegúrate de que utilsData.startDate y utilsData.endDate no son nulos
      if (!utilsData.startDate || !utilsData.endDate) {
        throw new Error("Las fechas de inicio y fin no están disponibles.")
      }

      // Extrae las horas y minutos del string proporcionado por el usuario
      const [startHour, startMinute] = startTimeString.split(":").map(Number)
      const [endHour, endMinute] = endTimeString.split(":").map(Number)

      // Actualiza solo las horas y minutos de los objetos Date
      const currentStartDate = new Date(utilsData.startDate.seconds * 1000)
      const currentEndDate = new Date(utilsData.endDate.seconds * 1000)
      currentStartDate.setHours(startHour, startMinute)
      currentEndDate.setHours(endHour, endMinute)

      // Convierte los objetos Date actualizados de nuevo a Timestamps
      const startTimestamp = Timestamp.fromDate(currentStartDate)
      const endTimestamp = Timestamp.fromDate(currentEndDate)

      // Actualiza el documento en Firestore
      await setDoc(docRef, { startDate: startTimestamp, endDate: endTimestamp })

      // Actualiza el estado local
      setUtilsData({ startDate: startTimestamp, endDate: endTimestamp })
    } catch (e) {
      console.error("Error updating 'utils' data:", e)
      setError(e)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchUtilsData()
  }, [])

  return {
    loading,
    error,
    utilsData,
    updateUtilsData,
  }
}

export default useUtils
