/**
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Otis Kit PRO base styles
import colors from "assets/theme/base/colors"
import borders from "assets/theme/base/borders"
import boxShadows from "assets/theme/base/boxShadows"

// Otis Kit PRO helper functions
import pxToRem from "assets/theme/functions/pxToRem"
import linearGradient from "assets/theme/functions/linearGradient"

const { transparent, gradients } = colors
const { borderRadius } = borders
const { colored } = boxShadows

export default {
  styleOverrides: {
    root: {
      background: linearGradient(gradients.info.main, gradients.info.state),
      padding: `${pxToRem(24)} 0 ${pxToRem(16)}`,
      borderRadius: borderRadius.lg,
      boxShadow: colored.info,

      "&.MuiPaper-root": {
        backgroundColor: transparent.main,
      },
    },
  },
}
