import { useEffect, useState } from "react"
import { collection, doc, getDocs, query, serverTimestamp, setDoc, where } from "firebase/firestore"
import moment from "moment"
import db from "../firebase"
import calendarConverter from "./classCalendar"
import useUser from "./useUser"
import useRoom from "./useRoom"

function useCheckin() {
  const [calendarError, setError] = useState(false)
  const [currentEvent, setCurrentEvent] = useState({})
  const [allCurrentEvents, setAllCurrentEvents] = useState([])
  const collectionRef = collection(db, "calendar")
  const { currentUser } = useUser()
  const { updateRoomStatus } = useRoom()

  async function getSingle(field, operator, value) {
    setError(false)
    const q = query(collectionRef, where(field, operator, value))
    const querySnapshot = await getDocs(q)
    if (querySnapshot.empty) {
      // eslint-disable-next-line
      setError("No matching documents")
      return null
    }
    let roomData = null
    querySnapshot.forEach((_room) => {
      roomData = { ..._room.data(), id: _room.id }
    })
    return roomData
  }

  async function getMany(field, operator, value) {
    setError(false)
    let q = query(collectionRef)
    if (field && value && operator) {
      q = query(collectionRef, where(field, operator, value))
    }
    const querySnapshot = await getDocs(q)
    if (querySnapshot.empty) {
      // eslint-disable-next-line
      setError("No matching documents")
      return null
    }
    const roomData = []
    querySnapshot.forEach((_room) => {
      roomData.push({ ..._room.data(), id: _room.id })
    })
    return roomData
  }

  async function updateEventWithCheckin() {
    if (!currentEvent || !currentEvent.id) {
      console.log("No valid currentEvent or currentEvent ID") // Log para manejar caso sin currentEvent o ID
      return
    }

    const { id, ...data } = currentEvent

    const newData = {
      ...data,
      status: "occupied",
      checkin: serverTimestamp(), // Usa tu función para obtener el timestamp del servidor
    }

    try {
      await setDoc(doc(db, "calendar", id), newData)
      console.log("Doc updated successfully") // Log para confirmar actualización exitosa
    } catch (error) {
      console.error("Error updating doc:", error) // Log para capturar y mostrar errores durante la actualización
      return
    }

    // Validar el número de habitación; ajusta esta validación según tus necesidades
    if (Number.isNaN(currentEvent.number)) {
      console.error("Invalid room number:", currentEvent.number) // Log para manejar número de habitación inválido
      return
    }

    // Intentar actualizar el status de la habitación, asegurarse de que updateRoomStatus está definido y funciona correctamente
    try {
      await updateRoomStatus({ number: currentEvent.number, status: "occupied" }) // Asegúrate de que esta función acepta estos argumentos y funciona como se espera
      console.log("Room status updated successfully") // Log para confirmar actualización de estado de habitación
    } catch (error) {
      console.error("Error updating room status:", error) // Log para capturar y mostrar errores durante la actualización de estado de habitación
    }
  }
  async function updateEventWithCheckout() {
    console.log("updateEventWithCheckout called")

    // Asegúrate de obtener el 'currentEvent' correctamente.

    if (!currentEvent || !currentEvent.id) {
      console.log("No valid currentEvent or currentEvent ID")
      return
    }

    // console.log("Current Event Before Update:", currentEvent)

    const { id, ...data } = currentEvent
    const newData = {
      ...data,
      status: "dirty",
      checkout: serverTimestamp(),
    }

    // console.log("Updating doc with id:", id, "New data:", newData)

    try {
      await setDoc(doc(db, "calendar", id), newData)
      console.log("Doc updated successfully")
    } catch (error) {
      console.error("Error updating doc:", error)
      return
    }

    if (Number.isNaN(currentEvent.number)) {
      // Usando Number.isNaN en lugar de isNaN
      console.error("Invalid room number:", currentEvent.number)
      return
    }

    try {
      await updateRoomStatus({ number: currentEvent.number, status: "dirty" })
      console.log("Room status updated successfully")
    } catch (error) {
      console.error("Error updating room status:", error)
    }
  }

  async function getCurrentEvent() {
    setError(false)
    let email = ""
    if (currentUser) {
      email = currentUser.email ?? ""
    } else {
      const item = JSON.parse(localStorage.getItem("user"))
      if (item) {
        email = item.email
      }
    }
    const today = moment().toDate()
    const q = query(
      collectionRef,
      where("endDate", ">", today),
      where("email", "==", email)
    ).withConverter(calendarConverter)
    const querySnapshot = await getDocs(q)
    if (querySnapshot.empty) {
      // eslint-disable-next-line
      setError("No matching documents")
      return []
    }
    const roomData = []
    querySnapshot.forEach((_room) => {
      roomData.push({ ..._room.data(), id: _room.id })
    })
    if (roomData.length === 0) return []
    const near = roomData.filter((room) => {
      const roomDate = new Date(room.startDate)
      const diff = Math.abs(moment(roomDate).diff(today, "hours"))
      // solo permite hacer checkin 24 horas antes o después
      return Math.abs(diff) <= 24
    })
    if (near.length === 0) return []

    const ordered = near.sort((a, b) => {
      const aDate = moment(a.startDate).toDate()
      const bDate = moment(b.startDate).toDate()
      return aDate - bDate
    })
    const selected = ordered[0] ?? []
    setCurrentEvent(selected)
    setAllCurrentEvents(ordered)

    console.log("Current Event:", selected)
    return selected
  }

  useEffect(() => {
    getCurrentEvent()
  }, [])

  return {
    getMany,
    getSingle,
    getCurrentEvent,
    currentEvent,
    allCurrentEvents,
    calendarError,
    updateEventWithCheckin,
    updateEventWithCheckout,
  }
}

export default useCheckin
