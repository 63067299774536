/* eslint-disable react/prop-types */
import { Checkbox, FormControlLabel, IconButton } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import MKBox from "components/MKBox"

function Accesory({ item, cacheRoom, setAccesory, deleteAccesoryByName }) {
  return (
    <MKBox sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={cacheRoom?.accessories?.some((accessory) => accessory.name === item.name)}
            onChange={(e) => setAccesory(e, item)} // Asegúrate de pasar el objeto completo
          />
        }
        name={item.name}
        label={item.titleEs ? `${item.title} (${item.titleEs})` : item.title}
      />
      <IconButton edge="end" aria-label="delete" onClick={() => deleteAccesoryByName(item.name)}>
        <DeleteIcon />
      </IconButton>
    </MKBox>
  )
}

export default Accesory
