/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useRef, useState } from "react"

// @mui material components
import Grid from "@mui/material/Grid"

// Otis Kit PRO components
import MKBox from "components/MKBox"
import MKInput from "components/MKInput"
import MKTypography from "components/MKTypography"
import { v4 as uuidv4 } from "uuid"

// Otis Kit PRO examples
import { Checkbox } from "@mui/material"
import useUser from "api/useUser"
import useCalendar from "api/useCalendar"
import CustomPricingCard from "examples/Cards/PricingCards/CustomPricingCard"
import { useAtom } from "jotai"
import { useNavigate } from "react-router-dom"
import { reservedEndDate, reservedStartDate } from "states/reservedDate"
import selectedPrice from "states/selectedPrice"
import { selectedType, maxOccupantsInType } from "states/selectedType"
import taxes from "constants/taxes"

// import roundTo from "tools/round"
import { sendEmailConfirmation, sendReservationReminder, sendCheckinMail } from "api/mail"
import { getShortDate } from "tools/getDate"
import MKButton from "components/MKButton"
import reservedAdultsAtom from "states/reservedAdults"
import reservedKidsAtom from "states/reservedKids"
import useNotifications from "api/useNotifications"
import getToken from "../../../api/ttLockApi"
import useUtils from "../../../api/useUtils"
import BlancLayout from "../Layouts/BlancLayout"
import LoginModal from "../Authentication/Login/LoginModal"
import PayButton from "./PayButton"

function Reserve() {
  const [paymentError, setPaymentError] = useState(false)
  const [startDate] = useAtom(reservedStartDate)
  const [endDate] = useAtom(reservedEndDate)
  // const [days] = useAtom(reservedDays)
  const [type] = useAtom(selectedType)
  const [max] = useAtom(maxOccupantsInType)
  const [price] = useAtom(selectedPrice)
  const [, setReservedAdults] = useAtom(reservedAdultsAtom)
  const [, setReservedKids] = useAtom(reservedKidsAtom)
  const navigate = useNavigate()
  const { room, addReservation, getAvailableRoom, updateReservationWithReminderByToken } =
    useCalendar({
      type,
      startDate,
      endDate,
    })
  const [terms, setTerms] = useState(false)
  const [licenseImageFile, setLicenseImageFile] = useState(null)
  const [licenseImagePreview, setLicenseImagePreview] = useState(null)
  const [formLicenseNumber, setFormLicenseNumber] = useState("")

  const {
    currentUser: user,
    logged,
    getAndUpdateUser,
    checkEmail,
    mailExists,
    addUser,
    login,
    uploadLicenseImage,
  } = useUser()
  const [formName, setFormName] = useState("")
  const [formLastName, setFormLastName] = useState("")
  const [formPhone, setFormPhone] = useState("")
  const [formAddress, setFormAddress] = useState("")
  const [formCity, setFormCity] = useState("")
  const [formCountry, setFormCountry] = useState("")
  const [formZipCode, setFormZipCode] = useState("")
  const [formEmail, setFormEmail] = useState("")
  const [formPassword, setFormPassword] = useState("")
  const [formConfirPassword, setFormConfirPassword] = useState("")
  const [coincidentPassword, setCoincidentPassword] = useState(true)
  const { addNotification } = useNotifications()
  const { utilsData } = useUtils()

  const date1 = new Date(startDate)
  const date2 = new Date(endDate)
  const diffTime = Math.abs(date2 - date1)
  const diffADays = Math.floor(diffTime / (1000 * 60 * 60 * 24))
  const precio = typeof price === "number" ? price : Number.parseFloat(price)
  const impuesto = taxes // Ya está en formato decimal (0.0625)

  useEffect(() => {
    if (user && user.license) {
      setLicenseImagePreview(user.license) // URL de la imagen actual
    }
    // Resto de tu lógica para establecer los estados iniciales...
  }, [user])
  const handleLicenseImageChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0]
      setLicenseImageFile(file)
      setLicenseImagePreview(URL.createObjectURL(file)) // Crear y guardar la URL de vista previa
    }
  }
  const calculateDiscount = (numberOfDays) => {
    if (numberOfDays === 7) {
      return precio
    }
    if (numberOfDays === 14) {
      return precio * 3
    }
    return 0
  }

  const discount = calculateDiscount(diffADays)
  const subtotal = diffADays * precio
  const totalTax = subtotal * impuesto // Aplica directamente
  const totalPrice = subtotal + totalTax - discount

  const roundedTotalPrice = Number(totalPrice.toFixed(2))

  const handleCheck = () => {
    setTerms(!terms)
  }

  const [party, setParty] = useState(0)
  const [adults, setAdults] = useState(0)
  const [kids, setKids] = useState(0)
  const count = useRef(0)
  useEffect(() => {
    count.current += 1
  })

  const handleAdultsChange = (e) => {
    const intAdults = parseInt(e.target.value, 10)
    const intKids = parseInt(kids, 10)
    const newParty = intAdults + intKids
    if (newParty > max) return
    if (intAdults < 1) return
    setParty(newParty)
    setAdults(intAdults)
  }
  const handleKidsChange = (e) => {
    const intKids = parseInt(e.target.value, 10)
    const intAdults = parseInt(adults, 10)
    const newParty = intAdults + intKids
    if (newParty > max) return
    if (intKids < 0) return
    setParty(newParty)
    setKids(intKids)
  }
  function formatDateToYYYYMMDDHHMM(date) {
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, "0")
    const day = date.getDate().toString().padStart(2, "0")
    const hours = date.getHours().toString().padStart(2, "0")
    const minutes = date.getMinutes().toString().padStart(2, "0")
    return `${year}${month}${day}${hours}${minutes}`
  }

  const onApprove = async (data, actions) => {
    // Primero, verificamos si el usuario ya está logueado
    const paymentAmount = roundedTotalPrice

    let usuario = JSON.parse(localStorage.getItem("user"))
    const currentDate = new Date()
    const currentHour = currentDate.getHours()
    const reservationDate = new Date(startDate)
    let licenseImageUrl = null
    if (licenseImageFile) {
      licenseImageUrl = await uploadLicenseImage(licenseImageFile)
      if (!licenseImageUrl) {
        console.log("Failed to upload license image")
        return
      }
    }

    if (!usuario) {
      // Creación del objeto de usuario:
      const newUser = {
        first_name: formName ?? "",
        last_name: formLastName ?? "",
        phone: formPhone ?? "",
        address: formAddress ?? "",
        city: formCity ?? "",
        country: formCountry ?? "",
        zipcode: formZipCode ?? "",
        email: formEmail ?? "",
        license: licenseImageUrl ?? "",
        license_number: formLicenseNumber ?? "",
        password: formPassword ?? "",
        id: uuidv4(),
        admin: false,
      }

      // Intentamos registrar el usuario:
      const registeredUser = await addUser(newUser)
      if (registeredUser) {
        // Si el usuario se registró correctamente, intentamos hacer el login:
        const loginSuccess = await login(newUser.email, newUser.password)
        if (!loginSuccess) {
          console.error("Error logging in after registration.")
          return
        }
        // Actualizamos el objeto usuario
        usuario = newUser
      } else {
        // Si el registro no fue exitoso, intentamos hacer el login:
        const loginSuccess = await login(newUser.email, newUser.password)
        if (!loginSuccess) {
          console.error("Error logging in.")
          return
        }
      }
    }

    // Ahora que el usuario está registrado y/o logueado, procedemos con el pago
    if (actions) {
      const details = await actions.order.capture()
      if (details.status === "COMPLETED") {
        // La captura fue exitosa, procesa el resultado
        console.log("Payment successful:", details)

        // Aquí manejarías la post-aprobación, como actualizar la base de datos
        // y redirigir al usuario a una página de confirmación
      } else {
        // Manejar errores o pagos no completados
        console.error("Payment not successful:", details)
      }
      console.log(`Transaction ${details.status} by ${details.payer.name.given_name}`)
    }

    const selectedRoom = await getAvailableRoom(type, startDate, endDate)
    console.log("selected room", room?.number ?? "ND")

    setReservedAdults(adults)
    setReservedKids(kids)

    // Actualizar la información del usuario si es necesario
    await getAndUpdateUser(usuario)
    // const startDateFormatted = formatDateToYYYYMMDDHHMM(new Date(startDate))
    // const endDateFormatted = formatDateToYYYYMMDDHHMM(new Date(endDate))
    // console.log("Esta es la fecha de inicio", startDate)
    // console.log("Esta es la fecha de inicio", endDate)

    // console.log("Esta es la fecha de inicio", startDateFormatted)
    // console.log("Esta es la fecha de fin", endDateFormatted)
    // console.log("Esta es la habitación seleccionada", selectedRoom.number)
    // const tokenResponse = await getToken(selectedRoom.number, startDateFormatted, endDateFormatted)
    // console.log("Este es el token", tokenResponse)

    const adjustedStartDate = new Date(startDate)
    adjustedStartDate.setHours(
      new Date(utilsData.startDate.seconds * 1000).getHours(),
      new Date(utilsData.startDate.seconds * 1000).getMinutes()
    )

    const adjustedEndDate = new Date(endDate)
    adjustedEndDate.setHours(
      new Date(utilsData.endDate.seconds * 1000).getHours(),
      new Date(utilsData.endDate.seconds * 1000).getMinutes()
    )
    // Ahora, usa adjustedStartDate y adjustedEndDate con formatDateToYYYYMMDDHHMM
    const startDateFormatted = formatDateToYYYYMMDDHHMM(adjustedStartDate)
    const endDateFormatted = formatDateToYYYYMMDDHHMM(adjustedEndDate)
    console.log("Esta es la fecha de inicio", startDateFormatted)
    console.log("Esta es la fecha de fin", endDateFormatted)
    console.log("Esta es la habitación seleccionada", selectedRoom.number)

    const tokenResponse = await getToken(selectedRoom.number, startDateFormatted, endDateFormatted)
    let response = null

    if (!tokenResponse) {
      response = "Error getting passcode, please contact front desk"
    } else {
      response = tokenResponse
    }
    localStorage.removeItem("keyboardPwd")
    localStorage.setItem("keyboardPwd", response)

    console.log("Este es el token", tokenResponse)

    const code = await addReservation(
      usuario.email,
      selectedRoom,
      startDate,
      endDate,
      paymentAmount,
      adults,
      kids,
      response
    )

    if (!code) return

    await sendEmailConfirmation(
      usuario.first_name,
      usuario.email,
      getShortDate(startDate),
      getShortDate(endDate)
    )
    if (
      reservationDate.toDateString() === currentDate.toDateString() &&
      currentHour >= 15 &&
      currentHour < 24
    ) {
      await sendReservationReminder(
        usuario.first_name,
        usuario.last_name,
        usuario.email,
        getShortDate(startDate),
        getShortDate(endDate),
        response
      )
      await sendCheckinMail(
        usuario.first_name,
        usuario.last_name,
        usuario.email,
        getShortDate(startDate),
        getShortDate(endDate),
        response
      )

      if (!tokenResponse) {
        console.log("Error getting passcode, please contact front desk")
      } else {
        await updateReservationWithReminderByToken(tokenResponse)
      }
    }

    await addNotification({
      email: usuario.email,
      text: `Your reservation has been confirmed. Your reservation code is ${code.substring(0, 6)}`,
    })

    setTimeout(() => {
      navigate(`/confirmation/${code}`)
    }, 500)
  }

  const onError = (error) => {
    console.error("Error during the payment:", error)
    setPaymentError(true)
  }

  const onNameChange = (e) => {
    setFormName(e.target.value)
  }

  const onLastNameChange = (e) => {
    setFormLastName(e.target.value)
  }

  const onPhoneChange = (e) => {
    setFormPhone(e.target.value)
  }

  const onAddressChange = (e) => {
    setFormAddress(e.target.value)
  }

  const onCityChange = (e) => {
    setFormCity(e.target.value)
  }

  const onCountryChange = (e) => {
    setFormCountry(e.target.value)
  }

  const onZipCodeChange = (e) => {
    setFormZipCode(e.target.value)
  }

  let countdown
  const onEmailChange = async (e) => {
    setFormEmail(e.target.value)
    clearTimeout(countdown)
    countdown = setTimeout(() => {
      checkEmail(e.target.value)
    }, 3000)
  }

  const onPasswordChange = (e) => {
    setFormPassword(e.target.value)
    setCoincidentPassword(formConfirPassword === e.target.value)
  }

  const onConfirmPasswordChange = (e) => {
    setFormConfirPassword(e.target.value)
    setCoincidentPassword(formPassword === e.target.value)
  }

  useEffect(() => {
    if (!user) return
    setFormName(user.first_name)
    setFormLastName(user.last_name)
    setFormPhone(user.phone)
    setFormAddress(user.address)
    setFormCity(user.city)
    setFormCountry(user.country)
    setFormZipCode(user.zipcode)
    setFormEmail(user.email)
    setFormLicenseNumber(user.license_number || "")
    setLicenseImagePreview(user.license || null)
  }, [user])

  return (
    <BlancLayout title="Reservación">
      <MKBox position="relative" zIndex={10} px={{ xs: 1, sm: 0 }}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={4}>
            <CustomPricingCard
              badge={{ color: "light", label: "Quotation" }}
              price={{
                currency: "$",
                value: roundedTotalPrice.toString(), // Muestra el precio total ya redondeado
              }}
              specifications={[
                { label: `Room: ${type}`, singlePrice: `$${precio.toFixed(2)}` },
                {
                  label: `${diffADays} day${diffADays > 1 ? "s" : ""}`,
                  singlePrice: `Subtotal: $${subtotal.toFixed(2)}`,
                },
                {
                  label: "Discount",
                  singlePrice: discount > 0 ? `-$${discount.toFixed(2)}` : "$0.00",
                  discount: discount > 0,
                },
                {
                  label: "Tax by day",
                  singlePrice: `${(impuesto * 100).toFixed(2)}%`,
                },
                {
                  label: "Total tax",
                  singlePrice: `$${totalTax.toFixed(2)}`,
                },
                {
                  label: "Check-in:",
                  singlePrice: startDate, // Asegúrate de formatear si es necesario
                },
                {
                  label: "Check-out:",
                  singlePrice: endDate, // Asegúrate de formatear si es necesario
                },
              ]}
              action={{
                type: "internal",
                route: "/innvie/confirmation",
                color: "error",
                label: "Pay now",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <MKBox p={3} bgColor="white" shadow="sm" borderRadius={10}>
              {!logged && <LoginModal />}
              <MKBox component="form" role="form">
                <Grid item xs={12}>
                  <MKBox mb={2}>
                    <MKInput
                      onChange={onNameChange}
                      value={formName}
                      type="text"
                      label="Name"
                      disabled={logged}
                      fullWidth
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput
                      type="text"
                      label="Lastname"
                      disabled={logged}
                      value={formLastName}
                      onChange={onLastNameChange}
                      fullWidth
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput
                      type="text"
                      label="Phone"
                      disabled={logged}
                      value={formPhone}
                      onChange={onPhoneChange}
                      fullWidth
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput
                      type="text"
                      label="Address"
                      disabled={logged}
                      value={formAddress}
                      onChange={onAddressChange}
                      fullWidth
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput
                      type="text"
                      label="City"
                      disabled={logged}
                      value={formCity}
                      onChange={onCityChange}
                      fullWidth
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput
                      type="text"
                      label="Country"
                      disabled={logged}
                      value={formCountry}
                      onChange={onCountryChange}
                      fullWidth
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput
                      type="text"
                      label="Zip Code"
                      disabled={logged}
                      value={formZipCode}
                      onChange={onZipCodeChange}
                      fullWidth
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput
                      type="email"
                      label="Email"
                      disabled={logged}
                      value={formEmail}
                      onChange={onEmailChange}
                      fullWidth
                    />
                  </MKBox>
                  {mailExists && (
                    <MKTypography
                      fontWeight="regular"
                      fontSize={12}
                      color="error"
                      textAlign="center"
                    >
                      This email is already registered. Please login.
                    </MKTypography>
                  )}
                  <MKBox mb={2}>
                    <MKInput
                      type="text"
                      label="License Number"
                      fullWidth
                      disabled={logged}
                      value={formLicenseNumber}
                      onChange={(e) => setFormLicenseNumber(e.target.value)}
                    />
                  </MKBox>

                  <MKTypography
                    variant="caption"
                    component="label"
                    fontWeight="bold"
                    color="text"
                    mb={1}
                  >
                    {localStorage.getItem("language") === "spanish"
                      ? "Imagen de la licencia"
                      : "License Image"}
                  </MKTypography>
                  <input
                    type="file"
                    onChange={handleLicenseImageChange}
                    accept="image/*"
                    disabled={logged}
                  />
                  {licenseImagePreview && (
                    <MKBox mt={2} textAlign="center">
                      <img
                        src={licenseImagePreview}
                        alt="Vista previa de la licencia"
                        style={{ maxWidth: "100%", maxHeight: "200px" }}
                      />
                    </MKBox>
                  )}
                </Grid>
                <Grid item xs={12} py={4}>
                  <Grid item>
                    <MKBox mb={2}>
                      <MKInput
                        type="number"
                        name="adults"
                        label="Adults"
                        value={adults}
                        onChange={handleAdultsChange}
                      />
                    </MKBox>
                    <MKBox mb={2}>
                      <MKInput
                        type="number"
                        name="children"
                        label="Kids"
                        value={kids}
                        onChange={handleKidsChange}
                      />
                    </MKBox>
                  </Grid>
                </Grid>
                {!logged && (
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKInput
                        type="password"
                        label="Password"
                        value={formPassword}
                        onChange={onPasswordChange}
                        fullWidth
                      />
                    </MKBox>
                    <MKBox mb={2}>
                      <MKInput
                        type="password"
                        label="Repeat password"
                        value={formConfirPassword}
                        onChange={onConfirmPasswordChange}
                        fullWidth
                      />
                    </MKBox>
                    {!coincidentPassword && (
                      <MKTypography
                        fontWeight="regular"
                        fontSize={12}
                        color="error"
                        textAlign="center"
                      >
                        Passwords do not match
                      </MKTypography>
                    )}
                  </Grid>
                )}
                <MKBox display="flex" alignItems="center" ml={-1}>
                  <Checkbox onChange={handleCheck} />
                  <MKTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;I Accept the&nbsp;
                  </MKTypography>
                  <MKTypography
                    component="a"
                    href="#"
                    variant="button"
                    fontWeight="bold"
                    color="info"
                    textGradient
                  >
                    Terms & Conditions
                  </MKTypography>
                </MKBox>
              </MKBox>
              {!terms || mailExists || !coincidentPassword || !formEmail || party < 1 ? (
                <MKTypography fontWeight="regular" color="error" sx={{ textAlign: "center" }}>
                  Please fill all the fields
                </MKTypography>
              ) : (
                <MKBox mt={3}>
                  {paymentError && (
                    <MKTypography
                      fontWeight="regular"
                      fontSize={12}
                      color="error"
                      textAlign="center"
                      mb={2}
                    >
                      Hubo un error con el pago. Por favor, inténtalo de nuevo.
                    </MKTypography>
                  )}
                  <PayButton price={roundedTotalPrice} onApprove={onApprove} onError={onError} />
                  <MKButton onClick={onApprove}>on aprobe</MKButton>
                </MKBox>
              )}
            </MKBox>
          </Grid>
        </Grid>
      </MKBox>
    </BlancLayout>
  )
}

export default Reserve
