import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js"
import PropTypes from "prop-types"

// pay button arrow function component
function PayButton({ price, onApprove }) {
  const stringPrice = parseFloat(price.toString()).toFixed(2)
  return (
    <PayPalScriptProvider
      options={{
        "client-id":
          "AQxnlm6RAf-JSFhsErbrZM7wpwBcsfhVPm-e_2itxdKusqQKnp_t8zr8v_ex8GUeBkZlwI3sYg5vddSE",
      }}
    >
      <PayPalButtons
        createOrder={(data, actions) =>
          actions.order.create({
            purchase_units: [{ amount: { value: stringPrice } }],
          })
        }
        onApprove={onApprove}
      />
    </PayPalScriptProvider>
  )
}
PayButton.propTypes = {
  price: PropTypes.number.isRequired,
  onApprove: PropTypes.func.isRequired,
}

export default PayButton
