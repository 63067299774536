import PropTypes from "prop-types"
import { Divider, IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useState } from "react"
import MKInput from "components/MKInput"
import useUser from "../../../../api/useUser"
import MKBox from "../../../../components/MKBox/index"
import MKTypography from "../../../../components/MKTypography/index"
import MKButton from "../../../../components/MKButton/index"

const { Modal, Slide } = require("@mui/material")

const language = localStorage.getItem("language")

const translations = {
  title: language === "english" ? "Recover Password" : "Recuperar Contraseña",
  email: language === "english" ? "Email" : "Correo",
  code: language === "english" ? "Code" : "Código",
  verifyCode: language === "english" ? "Verify Code" : "Verificar Código",
  codeSent:
    language === "english"
      ? "The code was sent to your email."
      : "El código fue enviado a tu correo.",
  resendCode: language === "english" ? "Resend code" : "Reenviar código",
  newPassword: language === "english" ? "New Password" : "Nueva Contraseña",
  confirmPassword: language === "english" ? "Confirm Password" : "Confirmar Contraseña",
  changePassword: language === "english" ? "Change Password" : "Cambiar Contraseña",
  sendEmail: language === "english" ? "Send Email" : "Enviar correo",
  invalidCode:
    language === "english"
      ? "Invalid code. Please try again."
      : "Código inválido. Por favor, inténtalo de nuevo.",
  mismatchedPasswords:
    language === "english" ? "Passwords do not match." : "Las contraseñas no coinciden.",
  passwordChangeError:
    language === "english"
      ? "There was an error changing the password. Please try again."
      : "Hubo un error al cambiar la contraseña. Por favor, inténtalo de nuevo.",
}
function PasswordRecoveryModal({ show, toggleModal }) {
  const [email, setEmail] = useState("")
  const [code, setCode] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [showCodeInput, setShowCodeInput] = useState(false)
  const [showResendButton, setShowResendButton] = useState(false)
  const [resendButtonEnabled, setResendButtonEnabled] = useState(true)
  const [showSendEmailButton, setShowSendEmailButton] = useState(true)
  const [emailInputEnabled, setEmailInputEnabled] = useState(true)
  const [isCodeValid, setIsCodeValid] = useState(false)
  const [error, setError] = useState(null)
  const { sendRecoveryEmail, resetPassword, verifyRecoveryCode } = useUser()

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!showCodeInput) {
      await sendRecoveryEmail(email)
      setShowSendEmailButton(false)
      setEmailInputEnabled(false)
      setShowCodeInput(true)
      setTimeout(() => {
        setShowResendButton(true)
      }, 5000)
    } else {
      const validCode = await verifyRecoveryCode(email, code)
      setIsCodeValid(validCode)
      if (!validCode) {
        setError("Código inválido. Por favor, inténtalo de nuevo.")
      }
    }
  }

  const handleVerifyCode = async () => {
    const validCode = await verifyRecoveryCode(email, code)
    setIsCodeValid(validCode)
    if (!validCode) {
      setError("Código inválido. Por favor, inténtalo de nuevo.")

      // Establece un temporizador para borrar el error después de 5 segundos
      setTimeout(() => {
        setError(null)
      }, 5000)
    }
  }

  const handleCloseModal = () => {
    toggleModal()
    setEmail("")
    setCode("")
    setShowCodeInput(false)
    setShowResendButton(false)
    setResendButtonEnabled(true)
    setShowSendEmailButton(true)
    setIsCodeValid(false)
    setError(null)
  }

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      setError("Las contraseñas no coinciden.")
      return
    }

    const success = await resetPassword(email, newPassword)
    if (success) {
      localStorage.setItem("passwordChanged", "true")
      handleCloseModal()
    } else {
      setError("Hubo un error al cambiar la contraseña. Por favor, inténtalo de nuevo.")
    }
  }

  const handleResendCode = async () => {
    if (!resendButtonEnabled) return

    // console.log("Reenviando código a:", email)
    await sendRecoveryEmail(email)
    setResendButtonEnabled(false)

    setTimeout(() => {
      setResendButtonEnabled(true)
    }, 10000)
  }

  return (
    <Modal open={show} onClose={handleCloseModal} sx={{ display: "grid", placeItems: "center" }}>
      <Slide direction="down" in={show} timeout={500}>
        <MKBox
          position="relative"
          width="500px"
          display="flex"
          flexDirection="column"
          borderRadius="xl"
          variant="gradient"
          bgColor="white"
          shadow="sm"
          padding="40px"
        >
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseModal}
            style={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseIcon />
          </IconButton>

          <MKBox display="flex" alignItems="center" justifyContent="center" py={3} px={2}>
            <MKTypography variant="h6" color="text">
              {translations.title}
            </MKTypography>
          </MKBox>
          <Divider light sx={{ my: 0 }} />

          <MKBox component="form" role="form" onSubmit={handleSubmit}>
            {!isCodeValid && (
              <>
                <MKBox mb={2}>
                  <MKInput
                    type="email"
                    label={translations.email}
                    variant="standard"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    disabled={!emailInputEnabled}
                    placeholder="john@example.com"
                    InputLabelProps={{ shrink: true }}
                  />
                </MKBox>
                {showCodeInput && (
                  <>
                    <MKBox mb={2}>
                      <MKInput
                        type="text"
                        label={translations.code}
                        variant="standard"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        fullWidth
                        placeholder="123456"
                        InputLabelProps={{ shrink: true }}
                      />
                    </MKBox>
                    <MKBox mb={2} display="flex" justifyContent="center">
                      <MKButton
                        onClick={handleVerifyCode}
                        variant="gradient"
                        color="error"
                        fullWidth
                      >
                        {translations.verifyCode}
                      </MKButton>
                    </MKBox>
                    <MKTypography
                      variant="body2"
                      color="error"
                      align="center"
                      style={{ marginBottom: "10px" }}
                    >
                      {translations.codeSent}
                    </MKTypography>
                    {showResendButton && (
                      <MKBox mb={2} display="flex" justifyContent="center">
                        <MKButton
                          onClick={handleResendCode}
                          variant="text"
                          color="primary"
                          style={{
                            opacity: resendButtonEnabled ? 1 : 0.5,
                            pointerEvents: resendButtonEnabled ? "auto" : "none",
                          }}
                        >
                          {translations.resendCode}
                        </MKButton>
                      </MKBox>
                    )}
                  </>
                )}
              </>
            )}
            {isCodeValid && (
              <>
                <MKBox mb={2}>
                  <MKInput
                    type="password"
                    label={translations.newPassword}
                    variant="standard"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    fullWidth
                    placeholder={translations.newPassword}
                    InputLabelProps={{ shrink: true }}
                  />
                </MKBox>
                <MKBox mb={2}>
                  <MKInput
                    type="password"
                    label={translations.confirmPassword}
                    variant="standard"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    fullWidth
                    placeholder={translations.confirmPassword}
                    InputLabelProps={{ shrink: true }}
                  />
                </MKBox>
                <MKButton onClick={handleResetPassword} variant="gradient" color="error" fullWidth>
                  {translations.changePassword}
                </MKButton>
              </>
            )}
            {error && (
              <p
                style={{ color: "red", fontSize: "0.8rem", marginTop: "-5px", marginBottom: "5px" }}
              >
                {error}
              </p>
            )}
            {showSendEmailButton && (
              <MKBox mt={4} mb={1}>
                <MKButton type="submit" variant="gradient" color="error" fullWidth>
                  {translations.sendEmail}
                </MKButton>
              </MKBox>
            )}
          </MKBox>
        </MKBox>
      </Slide>
    </Modal>
  )
}

PasswordRecoveryModal.propTypes = {
  show: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
}

export default PasswordRecoveryModal
