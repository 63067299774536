import { Card, Container, TextField, Button } from "@mui/material"
import useCalendar from "api/useCalendar"
import { useEffect, useState } from "react"
import useUtils from "../../api/useUtils"

function Ocupancy() {
  const { ocupancy, getOcupancy } = useCalendar({ type: null, startDate: null, endDate: null })
  const { utilsData, updateUtilsData } = useUtils()
  const [newStartHour, setNewStartHour] = useState("")
  const [newEndHour, setNewEndHour] = useState("")
  const language = localStorage.getItem("language") || "english"
  useEffect(() => {
    console.log(utilsData)
    getOcupancy()
    if (utilsData.startDate?.seconds && utilsData.endDate?.seconds) {
      // Convertir los Timestamps de Firestore a objetos Date
      const startDate = new Date(
        (utilsData.startDate.seconds + utilsData.startDate.nanoseconds / 1e9) * 1000
      )
      const endDate = new Date(
        (utilsData.endDate.seconds + utilsData.endDate.nanoseconds / 1e9) * 1000
      )

      // Formatea las fechas para que coincidan con el formato de entrada 'time' (HH:mm)
      const formattedStartTime = startDate.toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      })
      const formattedEndTime = endDate.toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      })

      // Actualiza el estado para mostrar las horas en los campos de entrada
      setNewStartHour(formattedStartTime)
      setNewEndHour(formattedEndTime)
    }
  }, [utilsData])

  const handleSave = async () => {
    await updateUtilsData(newStartHour, newEndHour)
  }
  return (
    <Container sx={{ mb: "20px" }}>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column", // Cambia la dirección a columna para organizar los elementos verticalmente
          justifyContent: "center",
          alignItems: "center", // Centra los elementos horizontalmente
          fontSize: "1.5rem",
          padding: "20px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: "16px" }}>
          {/* Envoltorio para los TextField */}
          <TextField
            label="Start Time"
            type="time"
            value={newStartHour}
            onChange={(e) => setNewStartHour(e.target.value)}
            sx={{ flex: 1, marginRight: "8px" }} // Flex: 1 para ocupar la mitad del espacio disponible
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="End Time"
            type="time"
            value={newEndHour}
            onChange={(e) => setNewEndHour(e.target.value)}
            sx={{ flex: 1, marginLeft: "8px" }} // Flex: 1 para ocupar la mitad del espacio disponible
            InputLabelProps={{ shrink: true }}
          />
        </div>
        {/* Botón separado de los TextField */}
        <Button variant="contained" onClick={handleSave} sx={{ width: "100%" }}>
          {language === "english" ? "Save" : "Guardar"}
        </Button>
      </Card>

      <br />
      <br />
      <Card
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          fontSize: "1.5rem",
        }}
      >
        <p>{language === "english" ? "Occupancy Today" : "Capacidad de Hoy"}</p>
        <p style={{ fontWeight: "bold", color: "red" }}>{ocupancy}%</p>
      </Card>
    </Container>
  )
}

export default Ocupancy
