import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useAtom } from "jotai"
import loggedUser from "states/loggedUser"
import Card from "@mui/material/Card"
import PropTypes from "prop-types"
import MKBox from "components/MKBox"
import MKTypography from "components/MKTypography"
import MKInput from "components/MKInput"
import MKButton from "components/MKButton"
import useUser from "api/useUser"
import PasswordRecoveryModal from "../../AuthenticationES/RecoveryPassword/PasswordRecoveryModal"

function Login({ modal }) {
  const [logged, setLogged] = useState(false)
  const { login, logout, getCurrentUser } = useUser()
  const [message, setMessage] = useState("")
  const [error, setError] = useState(null)
  const [showPasswordRecovery, setShowPasswordRecovery] = useState(false)
  const [user, setUser] = useAtom(loggedUser)

  useEffect(() => {
    if (getCurrentUser()) {
      setLogged(true)
      setUser(getCurrentUser())
    }
  }, [])

  const openPasswordRecoveryModal = () => {
    setShowPasswordRecovery(true)
  }

  const closePasswordRecoveryModal = () => {
    setShowPasswordRecovery(false)
  }

  const handleLogin = async (event) => {
    event.preventDefault()
    const success = await login(event.target.email.value, event.target.password.value)
    if (success) {
      setLogged(true)
      setError(null)
    } else {
      setLogged(false)
      setError("Incorrect username or password")
    }
  }

  const handleLogout = async () => {
    await logout()
    setLogged(false)
    setError(false)
  }

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email)
  }

  const handleChange = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError("Invalid email")
    } else {
      setError(null)
    }
    setMessage(event.target.value)
  }

  return (
    <Card>
      <MKBox
        variant="gradient"
        bgColor="primary"
        borderRadius="lg"
        coloredShadow="success"
        mx={2}
        mt={-3}
        p={3}
        mb={1}
        textAlign="center"
      >
        {logged ? (
          <MKTypography
            variant="h1"
            style={{ color: "white", margin: "1rem", textAlign: "center" }}
          >
            Welcome! {user?.first_name}
          </MKTypography>
        ) : (
          <>
            <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Sign In
            </MKTypography>
            <MKTypography display="block" variant="button" color="white" my={1}>
              Sign in with your email and password.
            </MKTypography>
          </>
        )}
      </MKBox>
      <MKBox pt={4} pb={3} px={3} component="form" role="form" onSubmit={handleLogin}>
        <MKBox>
          {!logged && (
            <>
              <MKBox mb={2}>
                <MKInput
                  type="email"
                  name="email"
                  label="Email"
                  variant="standard"
                  value={message}
                  onChange={handleChange}
                  fullWidth
                  placeholder="john@example.com"
                  InputLabelProps={{ shrink: true }}
                />
              </MKBox>
              {error && (
                <p
                  style={{
                    color: "red",
                    fontSize: "0.8rem",
                    marginTop: "-5px",
                    marginBottom: "5px",
                  }}
                >
                  {error}
                </p>
              )}
              <MKBox mb={2}>
                <MKInput
                  type="password"
                  name="password"
                  label="Password"
                  variant="standard"
                  fullWidth
                  placeholder="************"
                  InputLabelProps={{ shrink: true }}
                />
              </MKBox>
              <MKBox mt={4} mb={1}>
                <MKButton type="submit" variant="gradient" color="error" fullWidth>
                  Sign In
                </MKButton>
              </MKBox>
              <MKTypography
                variant="button"
                color="info"
                fontWeight="medium"
                onClick={openPasswordRecoveryModal}
                style={{ cursor: "pointer" }}
              >
                Forgot your password?
              </MKTypography>
              {!modal && (
                <MKBox mt={3} mb={1} textAlign="center">
                  <MKTypography variant="button" color="text">
                    Dont have an account?{" "}
                    <MKTypography
                      component={Link}
                      to="/authentication/register"
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      textGradient
                    >
                      Sign Up
                    </MKTypography>
                  </MKTypography>
                </MKBox>
              )}
            </>
          )}
          {logged && (
            <>
              <MKButton
                variant="contained"
                color="error"
                size="large"
                onClick={handleLogout}
                fullWidth
                style={{ marginBottom: "10px" }} // Added bottom margin
              >
                Log Out
              </MKButton>
              {!modal && (
                <MKButton component={Link} to="/" variant="gradient" color="error" fullWidth>
                  Go Back
                </MKButton>
              )}
            </>
          )}
        </MKBox>
      </MKBox>
      <PasswordRecoveryModal show={showPasswordRecovery} toggleModal={closePasswordRecoveryModal} />
    </Card>
  )
}

Login.defaultProps = {
  modal: false,
}

Login.propTypes = {
  modal: PropTypes.bool,
}

export default Login
