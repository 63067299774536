export default [
  { name: "History", align: "center" },
  { name: "ID", align: "left" },
  { name: "Type", align: "left" },
  { name: "Rooms", align: "center" },
  { name: "Queen", align: "center" },
  { name: "Full", align: "center" },
  { name: "Single", align: "center" },
  { name: "Status", align: "center" },
  { name: "Date_in", align: "center" },
  { name: "Check_in", align: "center" },
  { name: "Date_out", align: "center" },
  { name: "Check_out", align: "center" },
  { name: "User", align: "center" },
  { name: "Comments", align: "center" },
  { name: "Access_key", align: "center" },
]
