const getToken = async (alias, initDate, endDate) => {
  try {
    const url = `https://innviestay.com/apittlockkey/TTLock/getkeybyalias/${alias}/${initDate}/${endDate}`
    const response = await fetch(url)

    if (response.status === 200) {
      const data = await response.json()
      console.log(data)
      // Asegúrate de devolver la propiedad correcta según el objeto que esperas
      return data.keyboardPwd // Aquí se hace el cambio de Passcode a keyboardPwd
    }
    throw new Error(`Error getting passcode. Status: ${response.status}`)
  } catch (error) {
    throw new Error(`Error getting passcode: ${error.message}`)
  }
}

export default getToken
