/* eslint-disable camelcase */
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import {
  collection,
  deleteDoc,
  doc,
  documentId,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore"
import roundTo from "tools/round"
import moment from "moment/moment"
import db from "../firebase"
import useRoom from "./useRoom"
import useUser from "./useUser"
import { sendReservationChange, sendReservationReminder } from "./mail"
import useNotifications from "./useNotifications"
import useType from "./useType"
import useUtils from "./useUtils"

function useCalendar({ type, startDate, endDate }) {
  const [calendar, setCalendar] = useState([])
  const [calendarError, setError] = useState(false)
  const collectionRef = collection(db, "calendar")
  const { roomsAvailable, getRoomsNotInArray, updateRoomStatus } = useRoom()
  const { getUserByEmail } = useUser()
  const [available, setAvailable] = useState(false)
  const [room, setRoom] = useState({})
  const [typesNames, setTypesNames] = useState([])
  const { getRooms } = useRoom()
  const [ocupancy, setOcupancy] = useState(0)
  const { addNotification } = useNotifications()
  const { utilsData } = useUtils()
  const defaultRoom = {
    type: "",
    accessories: ["microwave", "desk", "tv", "dish", "wifi", "minifridge", "fullbath"],
    beds: { full: 0, queen: 0 },
    photos: [],
    price: 0,
    category: "Habitación",
  }
  const { getAll } = useType(defaultRoom)

  async function getSingle(field, operator, value) {
    setError(false)
    const q = query(collectionRef, where(field, operator, value))
    const querySnapshot = await getDocs(q)
    if (querySnapshot.empty) {
      // eslint-disable-next-line
      setError("No matching documents")
      return null
    }
    let roomData = null
    querySnapshot.forEach((_room) => {
      roomData = { ..._room.data(), id: _room.id }
    })
    return roomData
  }

  async function getMany(field, operator, value) {
    setError(false)
    let q = query(collectionRef)
    if (field && value && operator) {
      q = query(collectionRef, where(field, operator, value))
    }
    const querySnapshot = await getDocs(q)
    if (querySnapshot.empty) {
      // eslint-disable-next-line
      setError("No matching documents")
      return null
    }
    const roomData = []
    querySnapshot.forEach((_room) => {
      roomData.push({ ..._room.data(), id: _room.id })
    })
    return roomData
  }

  function checkIntersection(range1, range2) {
    return range1.startDate < range2.endDate && range2.startDate < range1.endDate
  }
  async function getOcupancy() {
    const rooms = await getRooms()
    const today = new Date()
    const q = query(collectionRef, where("endDate", ">=", today))
    const querySnapshot = await getDocs(q)
    const ocup = []
    querySnapshot.forEach((d) => {
      const dateRange = {
        ...d.data(),
        startDate: d.data().startDate.toDate(),
        endDate: d.data().endDate.toDate(),
      }
      ocup.push(dateRange)
    })
    if (ocup.length === 0) return 0
    const started = ocup.filter((o) => o.startDate <= today)
    const percentage = roundTo((started.length / rooms.length) * 100)
    setOcupancy(percentage)
    return percentage
  }

  async function getRoomAvailability(_room, _startDate, _endDate, _id = null) {
    const range = {
      startDate: new Date(_startDate ?? startDate),
      endDate: new Date(_endDate ?? endDate),
    }
    const results = []
    const today = new Date()
    let q = query(collectionRef, where("number", "==", _room), where("endDate", ">=", today))
    if (_id) {
      q = query(
        collectionRef,
        where("number", "==", _room),
        where("endDate", ">=", today),
        where(documentId(), "!=", _id)
      )
    }
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((d) => {
      const dateRange = {
        ...d.data(),
        startDate: d.data().startDate.toDate(),
        endDate: d.data().endDate.toDate(),
      }
      if (checkIntersection(range, dateRange)) {
        results.push({
          ...d.data(),
          startDate: d.data().startDate.toDate(),
          endDate: d.data().endDate.toDate(),
        })
      }
    })
    const found = results.find((r) => r.room === _room).length > 0
    setAvailable(!found)
    return !found
  }

  async function getAvailableTypes(_startDate, _endDate, _default) {
    const range = {
      startDate: new Date(_startDate ?? startDate),
      endDate: new Date(_endDate ?? endDate),
    }
    const results = []
    const today = new Date()
    const q = query(collectionRef, where("endDate", ">=", today))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((d) => {
      const dateRange = {
        ...d.data(),
        startDate: d.data().startDate.toDate(),
        endDate: d.data().endDate.toDate(),
      }
      if (checkIntersection(range, dateRange)) {
        results.push({
          ...d.data(),
          startDate: d.data().startDate.toDate(),
          endDate: d.data().endDate.toDate(),
        })
      }
    })
    const types = results.map((r) => r.type)
    const unique = [...new Set(types)]
    const typesList = await getAll()
    const availableTypes = typesList.filter((t) => !unique.includes(t.type))
    const typeNames = availableTypes.map((t) => t.type)
    if (!typeNames.includes(_default)) {
      typeNames.push(_default)
    }
    setTypesNames(typeNames)
    return typeNames
  }

  async function checkRoomAvailability(inputStartDate, inputEndDate, roomId) {
    const requestedRange = {
      startDate: new Date(inputStartDate),
      endDate: new Date(inputEndDate),
    }
    const q = query(
      collectionRef,
      where("room_id", "==", roomId),
      where("endDate", ">=", requestedRange.startDate)
    )
    const querySnapshot = await getDocs(q)

    // Utilizar 'some' para encontrar si alguna reserva se superpone
    const isRoomUnavailable = querySnapshot.docs.some((reservationDoc) => {
      const reservationData = {
        ...reservationDoc.data(),
        startDate: reservationDoc.data().startDate.toDate(),
        endDate: reservationDoc.data().endDate.toDate(),
      }
      return checkIntersection(requestedRange, reservationData)
    })

    return !isRoomUnavailable
  }

  async function getAvailability(_type, _startDate, _endDate, currentId = null) {
    const typeString = _type?.type || _type
    const range = {
      startDate: new Date(_startDate ?? startDate),
      endDate: new Date(_endDate ?? endDate),
    }
    const results = []
    const today = new Date()
    const q = query(collectionRef, where("type", "==", typeString), where("endDate", ">=", today))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((d) => {
      const dateRange = {
        ...d.data(),
        startDate: d.data().startDate.toDate(),
        endDate: d.data().endDate.toDate(),
      }
      if (checkIntersection(range, dateRange)) {
        results.push({
          ...d.data(),
          id: d.id,
          startDate: d.data().startDate.toDate(),
          endDate: d.data().endDate.toDate(),
        })
      }
    })
    if (currentId) {
      results.pop((r) => r.id === currentId)
    }

    const roomsfound = await getRoomsNotInArray(
      typeString,
      results.map((r) => r.number)
    )
    setAvailable(roomsfound.length > 0)
    return roomsfound
  }
  /* En la siguiente funcion, quiero basarme en la funcion getAvailability, pero tomar en consideracion el status de la habitacion obtenido en getRoomsNotInArray y setear activo true cuando sea available en la base de datos */
  async function getAvailabilityFour(_type, _startDate, _endDate, currentId = null) {
    const typeString = _type?.type || _type
    const range = {
      startDate: new Date(_startDate ?? startDate),
      endDate: new Date(_endDate ?? endDate),
    }
    const results = []
    const today = new Date()
    const q = query(collectionRef, where("type", "==", typeString), where("endDate", ">=", today))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((d) => {
      const dateRange = {
        ...d.data(),
        startDate: d.data().startDate.toDate(),
        endDate: d.data().endDate.toDate(),
      }
      if (checkIntersection(range, dateRange)) {
        results.push({
          ...d.data(),
          id: d.id,
          startDate: d.data().startDate.toDate(),
          endDate: d.data().endDate.toDate(),
        })
      }
    })
    if (currentId) {
      results.pop((r) => r.id === currentId)
    }

    const roomsfound = await getRoomsNotInArray(
      typeString,
      results.map((r) => r.number)
    )

    const availabilityPromises = roomsfound.map(async (rooms) => {
      let isRoomAvailable = false
      const roomStatus = rooms.status

      if (roomStatus === "available") {
        isRoomAvailable = true
      } else if (roomStatus === "occupied") {
        isRoomAvailable = await checkRoomAvailability(range.startDate, range.endDate, rooms.id)
      } else if (roomStatus === "dirty") {
        isRoomAvailable = false
      } else if (roomStatus === "maintenance") {
        isRoomAvailable = false
      }
      return isRoomAvailable
    })

    const availabilityResults = await Promise.all(availabilityPromises)
    setAvailable(availabilityResults.some((isAvailable) => isAvailable))

    return roomsfound.filter((_, index) => availabilityResults[index])
  }

  async function getAvailabilityTwo(_type, _startDate, _endDate, currentId = null) {
    const typeString = _type?.type || _type
    const range = {
      startDate: new Date(_startDate ?? startDate),
      endDate: new Date(_endDate ?? endDate),
    }
    console.log(
      `Verificando disponibilidad para tipo: ${typeString}, Desde: ${range.startDate}, Hasta: ${range.endDate}`
    )

    // Recopilar reservas existentes
    let results = []
    const q = query(
      collectionRef,
      where("type", "==", typeString),
      where("endDate", ">=", range.startDate)
    )
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((d) => {
      const dateRange = {
        ...d.data(),
        startDate: d.data().startDate.toDate(),
        endDate: d.data().endDate.toDate(),
      }
      if (checkIntersection(range, dateRange)) {
        results.push({
          ...d.data(),
          id: d.id,
          startDate: d.data().startDate.toDate(),
          endDate: d.data().endDate.toDate(),
        })
      }
    })

    // Excluir la reserva actual si existe
    if (currentId) {
      results = results.filter((r) => r.id !== currentId)
    }

    // Obtener todas las habitaciones y verificar su disponibilidad
    const allRooms = await getRoomsNotInArray(
      typeString,
      results.map((r) => r.number)
    )

    const availabilityPromises = allRooms.map(async (rooms) => {
      let isRoomAvailable = false
      const roomStatus = rooms.status

      if (roomStatus === "available") {
        isRoomAvailable = true
      } else if (roomStatus === "occupied") {
        isRoomAvailable = await checkRoomAvailability(range.startDate, range.endDate, rooms.id)
      } else if (roomStatus === "dirty") {
        isRoomAvailable = false
      } else if (roomStatus === "maintenance") {
        isRoomAvailable = false
      }
      console.log(`Habitación ${room.id} disponible: ${isRoomAvailable}`)
      return isRoomAvailable
    })

    const availabilityResults = await Promise.all(availabilityPromises)
    setAvailable(availabilityResults.some((isAvailable) => isAvailable))
    console.log(`Disponibilidad total: ${availabilityResults.some((isAvailable) => isAvailable)}`)
    return allRooms.filter((_, index) => availabilityResults[index])
  }
  async function getAvailableRoom(_type, _startDate, _endDate, currentId = null) {
    const avail = await getAvailability(_type, _startDate, _endDate, currentId)

    if (avail.length > 0) {
      const r = avail.sort((a, b) => a.number - b.number)[0]
      setRoom(r)
      return r
    }
    return {}
  }

  async function getCalendar() {
    const cal = await getMany()
    if (cal) {
      setCalendar(cal)
    }
    return cal
  }

  async function getReservationsByEmail(email) {
    const cal = await getMany("email", "==", email)
    if (cal) {
      setCalendar(cal)
    }
  }

  async function saveReservation(reservation) {
    let id = null
    let newReservationWithTimestamp = {
      ...reservation,
      lastUpdate: serverTimestamp(),
    }
    const hasPassed = moment(reservation.startDate).isBefore(moment())
    const hours = Math.abs(moment().diff(moment(reservation.startDate), "hours"))
    if (hasPassed || hours < 24) {
      newReservationWithTimestamp = {
        ...newReservationWithTimestamp,
        // checkin: moment().toDate(),
      }
    }
    try {
      const docRef = doc(collectionRef)
      await setDoc(docRef, newReservationWithTimestamp)
      id = docRef.id

      // Aquí añadimos el acceso_key basado en el ID del documento
      const access_key = id.substring(0, 6)
      await updateDoc(docRef, { access_key })
    } catch (e) {
      // eslint-disable-next-line
      console.log("error:", e);
      setError(e)
      return null
    }
    return id
  }

  const extractHourFromTimestamp = (timestamp) => {
    const date = timestamp.toDate()
    return moment(date).format("HH:mm")
  }

  async function addReservation(
    _email,
    _room,
    _startDate,
    _endDate,
    _price,
    _adults,
    _kids,
    _llave
  ) {
    console.log("reserving", _email, _room, _startDate, _endDate)

    const startTimeString = extractHourFromTimestamp(utilsData.startDate)
    const endTimeString = extractHourFromTimestamp(utilsData.endDate)

    const [startHour, startMinute] = startTimeString.split(":").map(Number)
    const [endHour, endMinute] = endTimeString.split(":").map(Number)

    const currentMoment = moment()
    const startMoment = moment(_startDate).set({ hour: startHour, minute: startMinute })
    const endMoment = moment(_endDate).set({ hour: endHour, minute: endMinute })
    let reservationMoment

    // Establecer el momento efectivo de la reserva
    if (currentMoment.isSame(startMoment, "day")) {
      if (currentMoment.isBefore(startMoment)) {
        reservationMoment = startMoment
      } else {
        reservationMoment = currentMoment
      }
    } else {
      reservationMoment = startMoment
    }

    if (reservationMoment.isAfter(endMoment)) {
      setError("Start date must be before end date")
      return null
    }

    if (!_room || Object.keys(_room).length === 0 || Array.isArray(_room)) {
      console.log("no room")
      setError("No room")
      return null
    }

    if (!_room.number) {
      console.error("No room number")
      setError("No room number")
      return null
    }

    if (!_room.type) {
      console.error("No room type")
      setError("No room type")
      return null
    }

    const foundUser = await getUserByEmail(_email)

    setError(false)
    const newReservationWithTimestamp = {
      type: _room.type,
      reservationDate: reservationMoment.toDate(),
      startDate: startMoment.toDate(),
      endDate: endMoment.toDate(),
      number: _room.number,
      email: _email,
      price: _price,
      first_name: foundUser.first_name,
      last_name: foundUser.last_name,
      lastUpdate: serverTimestamp(),
      room_id: _room.id,
      key: _llave,
    }

    if (_adults) {
      newReservationWithTimestamp.adults = _adults
    }

    if (_kids) {
      newReservationWithTimestamp.kids = _kids
    }

    const id = await saveReservation(newReservationWithTimestamp)
    await updateRoomStatus({ number: _room.number, status: "occupied" })
    return id
  }

  async function cancelReservation(id) {
    console.log("reservation ", id, " canceled")
    try {
      const documentRef = doc(collectionRef, id)
      await deleteDoc(documentRef)
      console.log("Document successfully deleted!")
    } catch (error) {
      console.log("Error fetching document:", error)
    }
  }

  async function updateCalendarEntry(_id, _startDate, _endDate, number, status) {
    let foundCalendar = calendar
    if (calendar.length === 0) {
      foundCalendar = await getCalendar()
    }
    const start_time = moment(_startDate).set("hour", 15).set("minute", 0)
    const end_time = moment(_endDate).set("hour", 11).set("minute", 30)
    const item = foundCalendar.find((i) => i.id === _id)
    const updatedItem = {
      ...item,
      start_time,
      end_time,
      number: number ?? item.number,
      status,
    }

    console.log(updatedItem.email)
    const updatedItemList = foundCalendar.map((i) => (i.id === _id ? updatedItem : i))
    setCalendar(updatedItemList)
    const docRef = doc(db, "calendar", _id)
    const data = {
      startDate: start_time.toDate(),
      endDate: end_time.toDate(),
      number: number ?? item.number,
      lastUpdate: serverTimestamp(),
      status,
      type: item.type,
    }
    await updateDoc(docRef, data)
    await sendReservationChange({
      name: updatedItem.title,
      email: updatedItem.email,
      check_in: updatedItem.start_time,
      check_out: updatedItem.end_time,
      room: updatedItem.group,
      access_key: updatedItem.id.substr(0, 6),
      type: updatedItem.type,
    })
    await addNotification({
      email: updatedItem.email,
      text: `Your reservation ${_id.substring(0, 6)} has been updated to room ${
        updatedItem.group
      } from ${updatedItem.start_time.format("DD.MM.YYYY")} to ${updatedItem.end_time.format(
        "DD.MM.YYYY"
      )}`,
    })
  }

  async function getReservationsToCheck() {
    const currentTime = moment()
    const reservations = await getMany()
    if (!reservations) {
      return [] // Retorna un array vacío si no hay reservaciones
    } // Asumimos que getMany() recupera todas las reservaciones

    const reservationsToCheck = reservations.filter((reservation) => {
      const reservationStartTime = moment(reservation.reservationDate.toDate()) // Convertir Timestamp a Date
      const hoursUntilReservation = reservationStartTime.diff(currentTime, "hours")
      return hoursUntilReservation <= 1 && hoursUntilReservation > 0
    })

    reservationsToCheck.forEach((reservation) => {
      sendReservationReminder(
        reservation.first_name,
        reservation.last_name,
        reservation.email,
        reservation.startDate.toDate(), // Convertir Timestamp a Date
        reservation.endDate.toDate() // Convertir Timestamp a Date
      )
    })

    return reservationsToCheck
  }
  async function updateReservationWithReminderByToken(tokenResponse) {
    const q = query(collection(db, "calendar"), where("key", "==", tokenResponse))

    const querySnapshot = await getDocs(q)
    if (querySnapshot.empty) {
      console.error("No se encontró la reserva con el token proporcionado.")
      return
    }

    const updatePromises = querySnapshot.docs.reduce(async (previousPromise, document) => {
      await previousPromise
      const reservationRef = doc(db, "calendar", document.id)
      return updateDoc(reservationRef, {
        reminderSent: true,
      })
    }, Promise.resolve())

    await updatePromises
    console.log("Reserva(s) actualizada(s) para marcar el recordatorio como enviado.")
  }

  useEffect(() => {
    if (type && startDate && endDate) {
      getAvailableRoom(type, startDate, endDate)
    }
  }, [])

  return {
    room,
    calendar,
    roomsAvailable,
    available,
    typesNames,
    addReservation,
    cancelReservation,
    updateCalendarEntry,
    getReservationsByEmail,
    getAvailableTypes,
    getAvailability,
    getRoomAvailability,
    getAvailableRoom,
    getCalendar,
    getMany,
    getSingle,
    getOcupancy,
    ocupancy,
    calendarError,
    getReservationsToCheck,
    getAvailabilityTwo,
    getAvailabilityFour,
    updateReservationWithReminderByToken,
  }
}

useCalendar.defaultProps = {
  type: "null",
  startDate: "null",
  endDate: "null",
}

useCalendar.propTypes = {
  type: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
}

export default useCalendar
